/* global axios */
import { MESSAGE_TYPE } from 'shared/constants/messages';
import { showBadgeOnFavicon } from './faviconHelper';
import { initFaviconSwitcher } from './faviconHelper';
import {
  getAlertAudio,
  initOnEvents,
  playCustomSound,
} from 'shared/helpers/AudioNotificationHelper';
import {loadedEventConfig} from '../../../widget/helpers/IframeEventHelper'
import conversationsAPI from '../../api/conversations';

const NOTIFICATION_TIME = 30000;
const NOTIFICATION_TELEGRAM_TIME = 15000;

class DashboardAudioNotificationHelper {
  constructor() {
    this.recurringNotificationTimer = null;
    this.recurringNotificationTelegramTimer = null;
    this.audioAlertType = 'none';
    this.playAlertOnlyWhenHidden = true;
    this.alertIfUnreadConversationExist = false;
    this.currentUserId = null;
    this.audioAlertTone = 'ding';
  }

  setInstanceValues = ({
    currentUserId,
    alwaysPlayAudioAlert,
    alertIfUnreadConversationExist,
    audioAlertType,
    audioAlertTone,
  }) => {
    this.audioAlertType = audioAlertType;
    this.playAlertOnlyWhenHidden = !alwaysPlayAudioAlert;
    this.alertIfUnreadConversationExist = alertIfUnreadConversationExist;
    this.currentUserId = currentUserId;
    this.audioAlertTone = audioAlertTone;
    initOnEvents.forEach(e => {
      document.addEventListener(e, this.onAudioListenEvent, false);
    });
    initFaviconSwitcher();
  };

  onAudioListenEvent = async () => {
    try {
      await getAlertAudio('', {
        type: 'dashboard',
        alertTone: this.audioAlertTone,
      });
      initOnEvents.forEach(event => {
        document.removeEventListener(event, this.onAudioListenEvent, false);
      });
      this.playAudioEvery30Seconds();
      // this.notificationTelegramEvery15Seconds();
    } catch (error) {
      // Ignore audio fetch errors
    }
  };

  playAudioEvery30Seconds = () => {
    //  Audio alert is disabled dismiss the timer
    if (this.audioAlertType === 'none') {
      return;
    }    
    // If assigned conversation flag is disabled dismiss the timer
    if (!this.alertIfUnreadConversationExist) {
      return;
    }

    this.clearSetTimeout();
  };

  executeRecurringNotification = async () => {
    if (!window.WOOT || !window.WOOT.$store) {
      this.clearSetTimeout();
      return;
    }
    const mineConversation = window.WOOT.$store.getters.getMineChats({
      assigneeType: 'me',
      status: 'open',
    });
    const hasUnreadConversation = mineConversation.some(conv => {
      return conv.unread_count > 0;
    });

    const shouldPlayAlert = !this.playAlertOnlyWhenHidden || document.hidden;

    if (hasUnreadConversation && shouldPlayAlert) {
      window.playAudioAlert();
      showBadgeOnFavicon();
    }
    this.clearSetTimeout();
  };

  clearSetTimeout = () => {
    console.log(this.recurringNotificationTimer)
    if (this.recurringNotificationTimer) {
      clearTimeout(this.recurringNotificationTimer);
    }
    this.recurringNotificationTimer = setTimeout(
      this.executeRecurringNotification,
      NOTIFICATION_TIME
    );
  };

  // clearSetTimeoutNotiTelegram = () => {
  //   if (this.recurringNotificationTelegramTimer) {
  //     clearTimeout(this.recurringNotificationTelegramTimer);
  //   }
  //   this.recurringNotificationTelegramTimer = setTimeout(
  //     this.executeRecurringNotificationTelegram,
  //     NOTIFICATION_TELEGRAM_TIME
  //   );
  // };


  // debounce = (func, timeout = 300) =>{
  //   let timer;
  //   return (...args) => {
  //     clearTimeout(timer);
  //     timer = setTimeout(() => { func.apply(this, args); }, timeout);
  //   };
  // }

  // debounceSendTelegram = this.debounce(async ()=> {
  //   await conversationsAPI.notitelegram();
  // }, 10000);


  // executeRecurringNotificationTelegram = async () => {
  //   if (!window.WOOT || !window.WOOT.$store) {
  //     this.clearSetTimeoutNotiTelegram();
  //     return;
  //   }
  //   const mineConversation = window.WOOT.$store.getters.getMineChats({
  //     assigneeType: 'me',
  //     status: 'open',
  //   });

  //   const hasUnreadConversation = mineConversation.some(conv => {
  //     return conv.unread_count > 0;
  //   });

  //   if (hasUnreadConversation) {
  //     // await conversationsAPI.notitelegram()
  //     this.debounceSendTelegram();
  //   }
  //   this.clearSetTimeoutNotiTelegram();
  // };


  // notificationTelegramEvery15Seconds = () => {
  //   this.clearSetTimeoutNotiTelegram();
  // };

  isConversationAssignedToCurrentUser = message => {
    const conversationAssigneeId = message?.conversation?.assignee_id;
    return conversationAssigneeId === this.currentUserId;
  };

  // eslint-disable-next-line class-methods-use-this
  isMessageFromCurrentConversation = message => {
    return (
      window.WOOT.$store.getters.getSelectedChat?.id === message.conversation_id
    );
  };

  isMessageFromCurrentUser = message => {
    return message?.sender_id === this.currentUserId;
  };

  // shouldNotifyOnMessage = message => {
  //   if (this.audioAlertType === 'mine') {
  //     return this.isConversationAssignedToCurrentUser(message);
  //   }
  //   return this.audioAlertType === 'all';
  // };

  changeNotifySound = (alertTone) => {
    initOnEvents.forEach(e => {
      document.addEventListener(e, this.onAudioListenEvent, false);
    });
  }

  gamefireNotifyLogic = (message) => {
    const { conversation, sender_id, message_type } = message;
    let triggerNotify = false;

    if (this.audioAlertType === 'mine') {
      let senders = localStorage.getItem('senders') || '[]';
      senders = JSON.parse(senders);

      if (conversation.assignee_id !== null) {
        triggerNotify = this.isConversationAssignedToCurrentUser(message);
        senders = senders.filter((inboxId) => inboxId !== sender_id);

        this.audioAlertTone = 'ding';
      }
      
      if (conversation.assignee_id === null) {
        const isActivity = message_type === MESSAGE_TYPE.ACTIVITY;
        const alreadyInbox = senders.find((inboxId) => inboxId === sender_id);
        const currentlyFocus = this.isMessageFromCurrentConversation(message);

        if (isActivity === false && alreadyInbox === undefined && currentlyFocus === false) {
          triggerNotify = true;
          senders = [...senders, sender_id];
        }

        this.audioAlertTone = 'new-visitor';
      }

      localStorage.setItem('senders', JSON.stringify(senders));
    }

    if (this.audioAlertType === 'all') {
      triggerNotify = true;
    }

    return triggerNotify;
  }

  onNewMessage = async (message) => {
    // If the message is sent by the current user or the
    // correct notification is not enabled, then dismiss the alert
    if (
      this.isMessageFromCurrentUser(message) ||
      !this.gamefireNotifyLogic(message)
    ) {
      return;
    }

    // If the message type is not incoming or private, then dismiss the alert
    const { message_type: messageType, private: isPrivate } = message;
    if (messageType !== MESSAGE_TYPE.INCOMING && !isPrivate) {
      return;
    }

    // If the user looking at the conversation, then dismiss the alert
    // if (this.isMessageFromCurrentConversation(message) && !document.hidden) {
    //   return;
    // }

    // If the user has disabled alerts when active on the dashboard, the dismiss the alert
    // if (this.playAlertOnlyWhenHidden && !document.hidden) {
    //   return;
    // }

    await playCustomSound('', {
      type: 'dashboard',
      alertTone: this.audioAlertTone,
    });
    
    // window.playAudioAlert();
    showBadgeOnFavicon();
    this.playAudioEvery30Seconds();
    this.notificationTelegramEvery15Seconds();
  };
}

export default new DashboardAudioNotificationHelper();
